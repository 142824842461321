import React from 'react';
import classNames from 'classnames/bind';

type Props = {
	children: any;
	className?: string;
	/**
	 * Headline size e.g. 1 for <h1>
	 */
	size?: 1 | 2 | 3 | 4;
};

const styles = require('../css/headline.scss');
const cx = classNames.bind(styles);

class Headline extends React.Component<Props, null> {
	render() {
		const CustomHeadlineTag = `h${this.props.size === undefined ? 1 : this.props.size}`;
		const headlineSize = this.props.size === undefined ? 1 : this.props.size;
		const className = `${cx(this.props.className, 'a-headline')} ${cx('a-headline')}--h${headlineSize}`;

		return <CustomHeadlineTag className={className}>{this.props.children}</CustomHeadlineTag>;
	}
}

export default Headline;
