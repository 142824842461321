import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames/bind';

const logo = require('../svg/the-muffin-tops.svg');
const styles = require('../css/logo.scss');
const cx = classNames.bind(styles);

const Logo = () => (
	<>
		<Link to="/" className={cx('a-logo')}>
			<img className={cx('a-logo__image')} src={logo} alt="The Muffin Tops" />
		</Link>
	</>
);

export default Logo;
