import React from 'react';
import classNames from 'classnames/bind';
import Scrollchor from 'react-scrollchor';

const styles = require('../css/navigation.scss');
const cx = classNames.bind(styles);

const Navigation = () => (
	<nav className={cx('m-navigation')}>
		<ul className={cx('m-navigation__list')}>
			<li className={cx('m-navigation__item')}>
				<Scrollchor to="#band" className={cx('m-navigation__link')} animate={{ offset: 0, duration: 800 }}>
					Band
				</Scrollchor>
			</li>
			<li className={cx('m-navigation__item')}>
				<Scrollchor to="#musik" className={cx('m-navigation__link')} animate={{ offset: 0, duration: 800 }}>
					Musik
				</Scrollchor>
			</li>
			<li className={cx('m-navigation__item')}>
				<Scrollchor to="#termine" className={cx('m-navigation__link')} animate={{ offset: 0, duration: 800 }}>
					Termine
				</Scrollchor>
			</li>
			<li className={cx('m-navigation__item')}>
				<Scrollchor to="#social" className={cx('m-navigation__link')} animate={{ offset: 0, duration: 800 }}>
					Social
				</Scrollchor>
			</li>
		</ul>
	</nav>
);

export default Navigation;
